import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import router from "./routes";
import "./registerServiceWorker";
import VueI18n from "vue-i18n";
import vnMessage from "./lang/vn.json";
import enMessage from "./lang/en.json";
import jpMessage from "./lang/jp.json";
import DatePicker from "vue2-datepicker";
import HighchartsVue from "highcharts-vue";
import Vuex from "vuex";
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-search-select/dist/VueSearchSelect.css";
import "vue2-datepicker/index.css";

import { apolloProvider } from "./graphql/graphql";
import './registerServiceWorker'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(DatePicker);
Vue.use(HighchartsVue, {
  chart: {
    style: {
      fontFamily: 'Google Sans'
    }
  },
});

if (process.env.NODE_ENV !== "development") {
  window.console.log = function () { };
  window.console.warn = function () { };
  window.console.error = function () { };
  window.console.info = function () { };
  window.console.debug = function () { };
}

const messages = {
  vn: vnMessage,
  en: enMessage,
  jp: jpMessage,
};

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "vn", // set locale
  messages,
  fallbackLocale: "vn",
});

console.log(process.env.COLOR)

new Vue({
  el: "#app",
  router,
  i18n,
  apolloProvider,
  store: store,
  render: (h) => h(App),
}).$mount();

<template>
  <div
    :class="isOpen ? ' open' : ''"
    class="quick-view"
    @click.self="closePopup()"
  >
    <div
      class="quick-view-content bg-white sm"
      :style="`width: ${width}; min-width: ${$isMobile() ? '100%' : '1000px'} `"
    >
      <div class="px-2 pt-2 bg-white shadow header-list">
        <div class="flex flex-row align-items-center">
          <div class="flex flex-1 mr-2">
            <p class="m-0" v-if="!isSingle">
              Đã chọn
              <strong class="font-14">{{ productSelects.length }}</strong> sản
              phẩm
            </p>
            <h5 class="m-0 no-break bold font-16" v-if="isSingle">
              Chọn sản phẩm
            </h5>
          </div>
          <div class="d-none d-md-flex">
            <auto-complete
              v-if="filterGroup"
              @changeOption="getData()"
              placeholder="Chọn nhóm SP"
              v-model="smallGroupId"
              :list="cates"
            />
            <auto-complete
              v-if="filterSupplier"
              class="ml-2"
              @changeOption="getData()"
              placeholder="Chọn nhà cung cấp"
              v-model="supplier"
              :list="suppliers.map((i) => ({ id: i.id, name: i.supplierName }))"
            />
            <auto-complete
              v-if="filterType"
              class="ml-2"
              @changeOption="getData()"
              placeholder="Chọn loại hàng"
              v-model="types"
              :list="[
                { id: 'STOCK', name: 'Hàng lưu kho' },
                { id: 'SERVICE', name: 'Hàng dịch vụ' },
              ]"
            />
          </div>
          <i
            v-if="supplier"
            class="fa fa-info-circle font-20 ml-1"
            v-b-tooltip.hover
            :title="'Giá của sản phẩm sẽ theo giá từng nhà cung cấp'"
          />
          <button
            @click="closePopup()"
            type="button"
            class="btn btn-basic pl-3 pr-3 mr-2"
          >
            {{ $t("common.cancel") }}
          </button>
          <button
            type="submit"
            v-if="!isSingle"
            class="btn btn-primary pl-3 pr-3 btn-sm"
            @click="save()"
          >
            <i class="fa fa-check" />

            {{ $t("common.done") }}
          </button>
        </div>

        <div class="tabs-border mt-0 flex flex-row align-items-stretch">
          <div
            class="tab"
            :class="{ active: tabActive == 'product' }"
            @click="tabActive = 'product'"
          >
            Sản phẩm
          </div>

          <div
            v-if="isGroup"
            class="tab"
            :class="{ active: tabActive == 'group' }"
            @click="tabActive = 'group'"
          >
            Nhóm hàng
          </div>

          <div
            v-if="!isSingle"
            class="tab"
            :class="{ active: tabActive == 'selected' }"
            @click="tabActive = 'selected'"
          >
            Sản phẩm đã chọn ({{ productSelects.length }})
          </div>
        </div>
      </div>
      <div class="bg-white" v-if="tabActive == 'group'">
        <div
          class="data-table"
          style="overflow: auto; height: calc(100lvh - 78px)"
        >
          <template v-for="large in categories">
            <div
              class="box-item p-0 pointer"
              @click="selectGroup(large, 'large')"
              :key="large.id"
            >
              <div class="d-flex align-items-center">
                <div>
                  <div class="h-30px w-0px bg-primary ml-20px" />
                  <div class="h-20px bg-primary ml-20px w-1px" />
                </div>

                <div
                  class="h-8px w-8px bg-primary ml--4px mr-5px border-round-5px"
                />

                <div>
                  <label> {{ large.code }} </label>
                  <p>{{ large.name }}</p>
                </div>
              </div>
            </div>
            <template v-for="(middle, middleIdx) in large.middles">
              <div
                class="box-item p-0 pointer"
                :key="middle.id + '' + large.id"
                @click="selectGroup(middle, 'middle')"
              >
                <div class="d-flex align-items-center">
                  <div
                    class="h-50px w-1px bg-primary ml-20px"
                    v-if="middleIdx <= large.middles.length - 2"
                  />
                  <div v-else>
                    <div class="h-25px w-1px bg-primary ml-20px" />
                    <div class="h-25px w-0px bg-primary ml-20px" />
                  </div>

                  <!-- <div class="h-50px w-1px bg-primary ml-20px" /> -->
                  <div class="h-1px w-30px bg-primary" />
                  <div>
                    <div class="h-30px w-0px bg-primary" />
                    <div class="h-20px bg-primary w-1px" />
                  </div>

                  <div
                    class="h-8px w-8px bg-primary ml--4px mr-5px border-round-5px"
                  />
                  <div>
                    <label> {{ middle.code }} </label>
                    <p>{{ middle.name }}</p>
                  </div>
                </div>
              </div>
              <template v-for="(small, smallIdx) in middle.smalls">
                <div
                  class="box-item p-0 pointer"
                  :key="middle.id + '' + large.id + '' + small.id"
                  @click="selectGroup(small, 'small')"
                >
                  <div class="d-flex align-items-center">
                    <div
                      class="h-50px w-1px ml-20px"
                      :class="
                        middleIdx <= large.middles.length - 2
                          ? 'bg-primary'
                          : ''
                      "
                    />

                    <div
                      class="h-50px w-1px bg-primary ml-30px"
                      v-if="smallIdx <= middle.smalls.length - 2"
                    />
                    <div v-else>
                      <div class="h-25px w-1px bg-primary ml-30px" />
                      <div class="h-25px w-0px bg-primary ml-30px" />
                    </div>

                    <div class="h-1px w-30px bg-primary" />

                    <div
                      class="h-8px w-8px bg-primary ml--4px mr-5px border-round-5px"
                    />
                    <div>
                      <label> {{ small.code }} </label>
                      <p>{{ small.name }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
      <div
        class="bg-white"
        v-if="tabActive == 'product' || tabActive == 'selected'"
      >
        <div
          class="d-block d-md-none"
          style="overflow: auto; height: calc(100lvh - 165px)"
        >
          <div
            v-for="product in tabActive == 'product'
              ? productList
              : productSelects"
            :key="product.id"
            :class="{ selected: isCheck(product) }"
            class="p-2"
          >
            <div class="box p-2" @click="onSelectProduct(product)">
              <div class="m-0 bold">
                <div class="d-flex align-items-center">
                  <div v-if="!isSingle" class="mr-2">
                    <check-box :value="isCheck(product)" />
                  </div>
                  {{ product.name }}
                  <p class="small mb-1">{{ product.secondaryName }}</p>
                  <span
                    class="tag tag-sm tag-primary-outline m-1"
                    v-b-tooltip="'Hàng dịch vụ, không  kiểm kho'"
                    v-if="product.noCheckWarehouse"
                  >
                    Dịch vụ
                  </span>
                  <span
                    class="tag tag-sm tag-warning-outline m-1"
                    v-b-tooltip="'Bán hơn số lượng tồn'"
                    v-if="product.saleOver"
                  >
                    Bán quá SL
                  </span>
                  <span
                    class="tag tag-sm tag-warning-outline m-1"
                    v-b-tooltip="'Sản phẩm sản xuất, chết biến'"
                    v-if="product.isBOM"
                  >
                    SX/ chế biến
                  </span>
                  <span
                    class="tag tag-sm tag-secondary-outline m-1"
                    v-b-tooltip="'Bán combo'"
                    v-if="product.isBundle"
                  >
                    Combo
                  </span>
                </div>
              </div>
              <p class="m-0 sub-text">Đơn vị: {{ product.unit.name }}</p>
              <p class="m-0 sub-text">SKU: {{ product.code }}</p>
              <p class="m-0 sub-text" v-if="product.upc">
                UPC: {{ product.upc }}
              </p>

              <p class="m-0 sub-text">
                {{
                  product.productType == "PRODUCTS"
                    ? "Thành phẩm"
                    : "Nguyên liệu"
                }}
              </p>
              <div
                v-for="field in fields"
                :key="field.key"
                class="text-right d-flex align-items-center mb-1"
              >
                <p class="flex flex-1">{{ field.label }}</p>
                <div v-if="field.type == 'percentTax'">
                  <quantity-input
                    class="form-control form-control-sm flex flex-1 text-right"
                    @input="onUpdateProduct(product)"
                    v-model="product[field.key]"
                    :decimal="field.decimal || 100"
                  />
                </div>

                <div v-else-if="field.type == 'inputQuantity'">
                  <QuantityUnitInput
                    :exchangeUnits="product.exchangeUnits"
                    :quantity="product[field.key]"
                    :decimal="field.decimal || 100"
                    @change="
                      (e) => {
                        product[field.key] = e.quantity;
                        product.transferUnit = e.unit;
                        onUpdateProduct(product);
                      }
                    "
                  />
                </div>
                <div
                  v-else-if="field.type == 'inputMoney'"
                  class="position-relative"
                >
                  <div class="position-absolute p-1" v-if="field.allowPercent">
                    <i
                      v-if="product[field.key + 'Type'] == 'PERCENT'"
                      @click="
                        product[field.key + 'Type'] = 'MONEY';
                        onUpdateProduct(product);
                      "
                      class="mdi mdi-percent font-20 text-info"
                    />
                    <i
                      v-else
                      @click="
                        product[field.key + 'Type'] = 'PERCENT';
                        product[field.key] =
                          product[field.key] > 100 ? 100 : product[field.key];
                        onUpdateProduct(product);
                      "
                      class="mdi mdi-currency-usd font-20 text-primary"
                    />
                  </div>
                  <quantity-input
                    class="form-control text-right form-control-sm"
                    v-model="product[field.key]"
                    :disabled="field.disabled == true"
                    v-if="product[field.key + 'Type'] == 'PERCENT'"
                    @input="onUpdateProduct(product)"
                    :decimal="field.decimal || 100"
                  />
                  <money-input
                    class="form-control text-right form-control-sm"
                    v-model="product[field.key]"
                    :disabled="field.disabled == true"
                    @input="onUpdateProduct(product)"
                    :decimal="field.decimal || 100"
                    v-else
                  />

                  <p
                    v-if="product.transfer != 1"
                    class="sub-text position-absolute right-0px"
                    style="bottom: -18px"
                  >
                    / 1 {{ product.transferUnit }}
                  </p>
                </div>
                <span v-else :class="field.className" class="p-2">
                  {{
                    field.showData
                      ? field.showData(product)
                      : product[field.key]
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="data-table d-none d-md-block"
          style="overflow: auto; height: calc(100lvh - 132px)"
        >
          <table class="table align-top">
            <thead>
              <tr>
                <th v-if="!isSingle" style="width: 30px">
                  <check-box v-model="allCheck" @change="checkAll()" />
                </th>
                <th class="w100" style="min-width: 300px">
                  {{ $t("product.name") }}
                </th>
                <th
                  v-for="field in fields"
                  :key="field.key"
                  class="nobreak text-right"
                  :style="{ width: field.width, minWidth: field.width }"
                >
                  {{ field.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="pointer"
                v-for="product in tabActive == 'product'
                  ? productList
                  : productSelects"
                :key="product.id"
                :class="{ selected: isCheck(product) }"
              >
                <td
                  v-if="!isSingle"
                  @click="
                    isCheck(product)
                      ? onUnSelectProduct(product)
                      : onSelectProduct(product)
                  "
                >
                  <check-box :value="isCheck(product)" />
                </td>
                <td class="w100" @click="onSelectProduct(product)">
                  <div class="m-0 bold">
                    {{ product.name }}
                    <p class="small mb-1">{{ product.secondaryName }}</p>
                    <span
                      class="tag tag-sm tag-primary-outline m-1"
                      v-b-tooltip="'Hàng dịch vụ, không  kiểm kho'"
                      v-if="product.noCheckWarehouse"
                    >
                      Dịch vụ
                    </span>
                    <span
                      class="tag tag-sm tag-info-outline m-1"
                      v-b-tooltip="'Sản phẩm sản xuất, chết biến'"
                      v-if="product.isBOM"
                    >
                      SX / chế biến
                    </span>
                    <span
                      class="tag tag-sm tag-warning-outline m-1"
                      v-b-tooltip="'Bán hơn số lượng tồn'"
                      v-if="product.saleOver"
                    >
                      Bán quá SL
                    </span>
                    <span
                      class="tag tag-sm tag-secondary-outline m-1"
                      v-b-tooltip="'Bán combo'"
                      v-if="product.isBundle"
                    >
                      Combo
                    </span>
                  </div>
                  <div class="d-flex">
                    <img
                      :src="product.image || '/images/no-image.jpg'"
                      width="60px"
                      height="60px"
                      style="object-fit: cover"
                    />
                    <div class="flex-fill ml-2">
                      <p class="m-0 sub-text">
                        Đơn vị: {{ product.unit.name }}
                      </p>
                      <p class="m-0 sub-text">SKU: {{ product.code }}</p>
                      <p class="m-0 sub-text" v-if="product.upc">
                        UPC: {{ product.upc }}
                      </p>
                      <p class="m-0 sub-text">
                        {{
                          product.productType == "PRODUCTS"
                            ? "Thành phẩm"
                            : "Nguyên liệu"
                        }}
                      </p>
                    </div>
                  </div>
                </td>
                <td v-for="field in fields" :key="field.key" class="text-right">
                  <div v-if="field.type == 'percentTax'">
                    <quantity-input
                      class="form-control flex flex-1 text-right"
                      v-model="product[field.key]"
                      @input="onUpdateProduct(product)"
                      :decimal="field.decimal || 100"
                    />
                  </div>
                  <div v-else-if="field.type == 'stock'" class="text-left">
                    <template v-if="product.stockAvailables.length">
                      <div
                        class="d-flex"
                        v-for="stock in product.stocks"
                        :key="stock.id"
                      >
                        <div class="box-150 mr-2">
                          <select
                            class="form-control"
                            v-model="stock.stockId"
                            @change="
                              countPrice();
                              onUpdateProduct(product);
                            "
                          >
                            <option
                              v-for="a in product.allStocks.leftJoin(
                                product.stocks,
                                'stockId',
                                stock.stockId
                              )"
                              :key="a.stockId"
                              :value="a.stockId"
                            >
                              {{ a.stockName }} ({{
                                $formatQuantity(a.availableQty)
                              }})
                            </option>
                          </select>
                        </div>
                        <div class="box-350">
                          <div
                            v-for="b in stock.batchNames"
                            :key="b.id"
                            class="d-flex mb-1"
                          >
                            <select
                              class="form-control flex-fill"
                              v-model="b.batchName"
                              @change="
                                countPrice();
                                onUpdateProduct(product);
                              "
                            >
                              <option
                                v-for="a in product.stockAvailables
                                  .filter((c) => c.stockId == stock.stockId)
                                  .leftJoin(
                                    stock.batchNames,
                                    'batchName',
                                    b.batchName
                                  )"
                                :key="a.id"
                                :value="a.batchName"
                              >
                                {{
                                  a.batchName == "-"
                                    ? "Lô không tên"
                                    : a.batchName
                                }}
                                ({{ $formatQuantity(a.availableQty) }})
                              </option>
                            </select>
                            <div class="w-10px" />
                            <!-- <quantity-input
                              class="form-control box-90 text-right"
                              :max="b.availableQty"
                              v-model="b.quantity"
                              :decimal="field.decimal || 100"
                              @change="onSelectProduct(product, true)"
                            /> -->

                            <QuantityUnitInput
                              :exchangeUnits="product.exchangeUnits"
                              :quantity="b.quantity"
                              :decimal="field.decimal || 100"
                              :max="b.availableQty"
                              @change="
                                (e) => {
                                  b.quantity = e.quantity;
                                  b.transferUnit = e.unit;
                                  onSelectProduct(product, true);
                                }
                              "
                            />
                            <button
                              class="btn btn-basic mb-1"
                              @click="
                                stock.batchNames.splice(bIdx, 1);
                                countPrice();
                                onUpdateProduct(product);
                              "
                            >
                              <i
                                class="fa fa-trash pointer text-danger font-16"
                              />
                            </button>
                          </div>
                          <p
                            v-if="
                              product.stockAvailables.filter(
                                (i) => i.stockId == stock.stockId
                              ).length > stock.batchNames.length
                            "
                            class="sub-text pointer text-primary pb-2"
                            @click="addBatchName(product, stock)"
                          >
                            + Lô hàng
                          </p>
                        </div>
                      </div>
                      <p
                        v-if="product.allStocks.length > product.stocks.length"
                        class="sub-text pointer text-primary"
                        @click="addStock(product)"
                      >
                        + Kho
                      </p>
                    </template>
                    <div v-else class="text-center text-warning font-20 bold">
                      Hết hàng
                    </div>
                  </div>

                  <div v-else-if="field.type == 'inputQuantity'">
                    <QuantityUnitInput
                      :exchangeUnits="product.exchangeUnits"
                      :quantity="product[field.key]"
                      :decimal="field.decimal || 100"
                      @change="
                        (e) => {
                          product[field.key] = e.quantity;
                          product.transferUnit = e.unit;
                          onUpdateProduct(product);
                        }
                      "
                    />
                  </div>
                  <div
                    v-else-if="field.type == 'inputMoney'"
                    class="position-relative"
                  >
                    <div
                      class="position-absolute p-1"
                      v-if="field.allowPercent"
                    >
                      <i
                        v-if="product[field.key + 'Type'] == 'PERCENT'"
                        @click="
                          product[field.key + 'Type'] = 'MONEY';
                          onSelectProduct(product)();
                        "
                        class="mdi mdi-percent font-20 text-info"
                      />
                      <i
                        v-else
                        @click="
                          product[field.key + 'Type'] = 'PERCENT';
                          product[field.key] =
                            product[field.key] > 100 ? 100 : product[field.key];
                          onSelectProduct(product)();
                        "
                        class="mdi mdi-currency-usd font-20 text-primary"
                      />
                    </div>
                    <quantity-input
                      class="form-control text-right"
                      v-model="product[field.key]"
                      :disabled="field.disabled == true"
                      v-if="product[field.key + 'Type'] == 'PERCENT'"
                      @input="onSelectProduct(product)"
                      :decimal="field.decimal || 100"
                    />
                    <money-input
                      class="form-control text-right"
                      v-model="product[field.key]"
                      :disabled="field.disabled == true"
                      @input="onSelectProduct(product)"
                      v-else
                    />

                    <p
                      v-if="product.transfer != 1"
                      class="sub-text position-absolute right-0px"
                      style="bottom: -18px"
                    >
                      / 1 {{ product.transferUnit }}
                    </p>
                  </div>
                  <span v-else :class="field.className">
                    {{
                      field.showData
                        ? field.showData(product)
                        : product[field.key]
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="bg-white d-md-flex align-items-center justify-content-between p-2 pr-4"
        >
          <div class="d-flex align-items-center">
            <select
              class="form-control form-control-sm box-60 mr-2"
              v-model="limit"
              @change="changeLimit()"
            >
              <option v-for="l in $allLimit" :key="l">{{ l }}</option>
            </select>
            <div class="flex-1" />
            <Pagination
              ref="paging"
              :total="totalPage"
              :currentPage="page"
              @changePage="onChangePage"
            />
          </div>
          <div class="flex flex-md-300px position-relative">
            <div
              class="position-absolute bg-white border w-100 rounded shadow"
              style="bottom: 35px"
              v-if="showDrop && oldKeySearchs.length > 0"
            >
              <span
                v-for="k in oldKeySearchs.filter((a) => a.keySearch)"
                :key="k.keySearch"
                class="m-1 p-1 border font-12 bg-white pointer d-inline-block rounded-lg"
                @click="
                  keySearch = k.keySearch;
                  search();
                "
              >
                <i class="fa fa-history" />
                {{ k.keySearch }}
              </span>
            </div>

            <input
              maxlength="200"
              class="form-control font-14"
              placeholder="Tìm kiếm theo tên, mã. Thêm [/] để tìm chính xác theo mã"
              v-model="keySearch"
              :autofocus="true"
              @keyup.enter="search()"
              @focus="showDrop = true"
              @click="showDrop = true"
              @blur="onBlurSearch()"
            />
          </div>
        </div>
        <div v-if="loading" class="loading">
          <div>
            <div class="loading-spin">
              <i></i>
            </div>
            <p>{{ $t("common.loading") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/api";
import Pagination from "@/components/Pagination";
import QuantityUnitInput from "./QuantityUnitInput.vue";
export default {
  name: "SelectProduct",
  components: { Pagination, QuantityUnitInput },
  watch: {
    "$route.path"() {
      this.keySearch = "";
      this.isOpen = false;
    },
  },
  computed: {},
  data: function () {
    return {
      oldKeySearchs: [],
      showDrop: false,
      width: "calc(100vw - 500px)",
      cates: [],
      tabActive: "product",
      allCheck: true,
      isOpen: false,
      productList: [],
      keySearch: "",
      limit: 25,
      page: 1,
      total: 0,
      totalPage: 1,
      loading: true,
      productSelects: [],
      isSingle: false,
      fields: [],
      suppliers: [],
      filterGroup: false,
      filterSupplier: false,
      filterType: false,
      types: [],
      supplier: "",
      smallGroupId: "",
      allowTransferUnit: false,
      isGroup: false,
      categories: [],
    };
  },
  methods: {
    addStock(record) {
      var stocks = record.allStocks.leftJoin(record.stocks, "stockId");

      var stock = stocks[0];

      var batchNames = record.stockAvailables.filter(
        (i) => i.stockId == stock.stockId
      );

      const batch = batchNames[0];

      record.stocks.push({
        quantity: 1,
        stockId: stock.stockId,
        batchNames: [
          {
            batchName: batch.batchName,
            quantity: 1,
            maxQuantity: batch.quantity,
            expiryDate: batch.expiryDate,
            manufacturingDate: batch.manufacturingDate,
          },
        ],
      });
      this.countPrice();
      this.onUpdateProduct(record);
    },
    addBatchName(record, stock) {
      var batch = record.stockAvailables
        .filter((i) => i.stockId == stock.stockId)
        .leftJoin(stock.batchNames, "batchName")[0];
      stock.batchNames.push({
        batchName: batch.batchName,
        quantity: 1,
        maxQuantity: batch.quantity,
        expiryDate: batch.expiryDate,
        manufacturingDate: batch.manufacturingDate,
      });
      this.countPrice();
      this.onUpdateProduct(record);
    },
    countPrice() {
      this.productList.forEach((item) => {
        item.stocks = item.stocks.map((stock) => {
          stock.batchNames.forEach((b) => {
            var nb =
              item.stockAvailables
                .filter((i) => i.stockId == stock.stockId)
                .find((i) => i.batchName == b.batchName) || {};

            b.maxQuantity = nb.quantity;
            b.expiryDate = nb.expiryDate;
            b.manufacturingDate = nb.manufacturingDate;

            if (b.maxQuantity < nb.quantity) {
              b.quantity = nb.maxQuantity;
            }
          });
          return stock;
        });
        item.stocks = item.stocks.filter((i) => i.batchNames.length > 0);
        item.inputQuantity = item.stocks.reduce((a, b) => {
          b.quantity = b.batchNames.reduce((c, d) => c + +d.quantity, 0);
          return a + +b.quantity;
        }, 0);

        item.quantity = item.quantity > 0 ? item.quantity : item.quantity * -1;
        item.quantity = Math.round(item.quantity * 100) / 100;
        item.total = Math.round(item.quantity * item.price);
      });
    },

    onBlurSearch() {
      setTimeout(() => {
        this.showDrop = false;
      }, 300);
    },
    selectGroup(data, type) {
      this._save({ ...data, type });
      this.isOpen = false;
    },
    getSuppliers() {
      this.$api
        .getSuppliers({ "companies.id": this.$companyId }, 1000, 0, "id_asc")
        .then((res) => {
          this.suppliers = [{ id: "", supplierName: "Tất cả NCC" }].concat(res);
        });
    },
    checkAll() {
      if (this.allCheck) {
        this.productList.map((product) => {
          var item = this.productSelects.find((i) => i.id == product.id);
          if (!item) {
            this.productSelects.push(product);
          }
        });
      } else {
        this.productList.map((product) => {
          var item = this.productSelects.find((i) => i.id == product.id);
          if (item) {
            this.productSelects = this.productSelects.filter(
              (i) => i.id != product.id
            );
          }
        });
      }
      this.updateCheckAll();
    },
    updateCheckAll() {
      var allCheck = true;
      var isNone = true;
      this.productList.map((product) => {
        var item = this.productSelects.find((i) => i.id == product.id);
        if (!item) {
          allCheck = 2;
        } else {
          isNone = false;
        }
      });
      this.allCheck = isNone ? false : allCheck;
    },
    isCheck(item) {
      return this.productSelects.find((i) => i.id == item.id);
    },
    syncQuantity() {
      this.productList = this.productList.map((a) => {
        const item = this.productSelects.find((i) => i.id == a.id);
        if (item) {
          this.fields.forEach((field) => {
            a[field.key] = item[field.key];
          });
          a["stocks"] = item["stocks"];
        }
        return a;
      });
      this.$forceUpdate();
    },
    onSelectProduct(product) {
      if (this.isSingle) {
        this.isOpen = false;
        this._save(product);
        return;
      }
      if (this.tabActive == "product") {
        const index = this.productSelects.map((i) => i.id).indexOf(product.id);
        if (index > -1) {
          this.productSelects[index] = product;
        } else {
          this.productSelects.push(product);
        }
        this.productSelects = JSON.parse(JSON.stringify(this.productSelects));
      }
      if (this.tabActive == "selected") {
        this.syncQuantity();
      }
      this.updateCheckAll();
    },
    onUpdateProduct(product) {
      if (this.tabActive == "product") {
        const index = this.productSelects.map((i) => i.id).indexOf(product.id);
        if (index > -1) {
          this.productSelects[index] = product;
          this.productSelects = JSON.parse(JSON.stringify(this.productSelects));
        }
      }
      if (this.tabActive == "selected") {
        this.syncQuantity();
      }
      this.$forceUpdate();
    },
    onUnSelectProduct(product) {
      this.productSelects = this.productSelects.filter(
        (i) => i.id != product.id
      );
    },
    onChangePage(data) {
      this.page = data;
      this.getData();
    },
    changeLimit() {
      this.getData();
    },

    search() {
      this.page = 1;
      this.getData();

      this.showDrop = false;
      if (this.keySearch) {
        this.$api
          .newSearchHistory(this.$companyId, {
            userId: this.$user.id,
            keySearch: this.keySearch,
            model: "PRODUCT_SEARCH",
            branchId: this.$branchId,
          })
          .then(() => {
            this.getSearchHistory();
          });
      }
    },
    getSearchHistory() {
      this.$api
        .getSearchHistory(
          {
            "users.id": this.$user.id,
            "branches.id": this.$branchId,
            companyId: this.$companyId,
            model: "PRODUCT_SEARCH",
          },
          4,
          0,
          "searchTime_desc"
        )
        .then((res) => {
          this.oldKeySearchs = res.list;
        });
    },
    async getData() {
      let where = {
        branchId: this.$branchId,
      };
      this.loading = true;
      if (this.keySearch) {
        if (this.keySearch.indexOf("/") === 0) {
          where["OR"] = [
            {
              code_ilike: this.keySearch.substring(1),
            },
          ];
        } else {
          where["OR"] = [
            {
              searchText_fulltextBool: this.keySearch,
            },
            {
              searchText_ilike: this.keySearch,
            },
          ];
        }
      }
      if (this.supplier) {
        where["productSuppliers.suppliers.id"] = this.supplier;
      }
      if (this.smallGroupId) {
        where["smallGroups.id"] = this.smallGroupId;
      }
      if (this.types) {
        if (this.types.indexOf("STOCK") > -1) {
          where["noCheckWarehouse"] = false;
        }
        if (this.types.indexOf("SERVICE") > -1) {
          where["noCheckWarehouse"] = true;
        }
        if (
          this.types.indexOf("SERVICE") > -1 &&
          this.types.indexOf("STOCK") > -1
        ) {
          where["noCheckWarehouse"] = undefined;
        }
      }
      where = {
        ...where,
        ...this.forceWhere,
      };
      var res = await api.getProducts(
        where,
        this.limit,
        (this.page - 1) * this.limit,
        this.keySearch ? "" : "id_desc",
        true
      );
      var stockAvailables = [];
      if (this.fields.find((a) => a.type == "stock")) {
        const t = await api.getSmartInventory(
          {
            branchId: this.$branchId,
            productIds: res.list.map((i) => i.id),
          },
          10000,
          0,
          "id_desc"
        );

        stockAvailables = t.list
          .map((a) => ({
            ...a,
            batchName: a.batchName || "-",
            availableQty: Math.max(0, a.availableQty),
          }))
          .filter((a) => a.availableQty > 0);
      }
      this.loading = false;
      this.productList = res.list.map((product) => {
        const stt = product.productStatuses.find(
          (i) => i.branches.id == this.$branchId
        );
        product.quantity = stt ? stt.quantity : 0;
        product.quantity = Math.round(product.quantity * 100) / 100;
        product.inputQuantity = 0;
        product.cost = product.productCostPrices[0]
          ? product.productCostPrices[0].costPrice
          : 0;
        var productBranchConfig =
          product.productBranchConfigs.find(
            (a) => a.branches.id == this.$branchId
          ) || {};
        product.minQuantity = productBranchConfig.minQuantity || 0;
        product.maxQuantity = productBranchConfig.maxQuantity || 0;

        var priceObj = product.productPrices.find(
          (i) => i.branches.id == this.$branchId
        );
        if (this.priceLevelId) {
          priceObj = product.productPrices.find(
            (i) =>
              i.branches.id == this.$branchId &&
              i.priceLevel.id == this.priceLevelId
          );
        }
        product.price = priceObj ? priceObj.price : product.priceDefault;
        if (this.supplier) {
          var s = product.productSuppliers.find(
            (i) => i.suppliers.id == this.supplier
          );
          product.cost = s ? s.price : product.cost;
        }
        product.costPrice = product.cost;
        product.transferUnit = product.unit.name;
        product.transfer = 1;

        product.percentTax = 0;

        var exchangeUnit =
          this.$units.find((a) => a.name == product.unit.name) || {};
        product.exchangeUnits = [
          {
            transferName: product.unit.name,
            transferValue: 1,
          },
        ]
          .concat(exchangeUnit.transferUnits)
          .map((a) => {
            return {
              name: a.transferName,
              id: a.transferName,
              transfer: a.transferValue,
            };
          });
        product.stockAvailables = stockAvailables.filter(
          (s) => product.id == s.prdId
        );
        product.allStocks = product.stockAvailables.mergeTotal(
          "stockId",
          "quantity,availableQty"
        );

        var stockId = product.allStocks[0] && product.allStocks[0].stockId;
        var batch =
          product.stockAvailables.find((a) => a.stockId == stockId) || {};
        product.stocks = [
          {
            stockId: stockId,
            batchNames: [
              {
                batchName: batch.batchName,
                quantity: 1,
                availableQty: batch.availableQty,
              },
            ],
          },
        ];

        this.countPrice();

        if (this.smalls && this.smalls.length) {
          var c =
            this.smalls.find((a) => a.smallId == product.smallGroups.id) || {};

          product.productId = product.id;
          product.productName = product.name;
          product.productCode = product.code;
          product.largeId = c.largeId;
          product.largeName = c.largeName;
          product.largeCode = c.largeCode;
          product.middleId = c.middleId;
          product.middleName = c.middleName;
          product.middleCode = c.middleCode;
          product.smallId = c.smallId;
          product.smallName = c.smallName;
          product.smallCode = c.smallCode;
        }

        return product;
      });
      this.total = res.totalCount;
      this.totalPage = Math.ceil(this.total / this.limit);
      this.updateCheckAll();
      this.syncQuantity();
      this.$forceUpdate();
    },
    openPopup(where, config = {}) {
      if (config) {
        this.fields = config.fields || [];
        this.haveConfirm = config.haveConfirm;
      }
      this.width = config.width || "calc(100vw - 500px)";
      this.isSingle = config.isSingle || false;
      this.filterGroup = config.filterGroup || false;
      this.filterSupplier = config.filterSupplier || false;
      this.filterType = config.filterType || false;
      this.isGroup = config.isGroup || false;

      this.allowTransferUnit = config.allowTransferUnit || false;
      this.priceLevelId = config.priceLevelId;
      this.isOpen = true;
      this.forceWhere = where || {};
      this.tabActive = "product";

      this.supplier = "";
      this.smallGroupId = "";
      this.cates = [{ id: "", name: "Tất cả nhóm" }].concat(this.$categories);
      this.loading = false;
      this.productSelects = [];
      this.productList = [];

      this.getSearchHistory();

      this.getData();
      if (this.filterSupplier) {
        this.getSuppliers();
      }
      if (this.isGroup) {
        this.$api
          .getCategoriesTree({ "companies.id": this.$companyId })
          .then((res) => {
            this.smalls = [];
            this.categories = res.list.map((l) => {
              l.largeId = l.id;
              l.largeName = l.name;
              l.largeCode = l.code;
              l.middles = l.middles.map((m) => {
                m.largeId = l.id;
                m.largeName = l.name;
                l.largeCode = l.code;
                m.middleId = m.id;
                m.middleName = m.name;
                m.middleCode = m.code;
                m.smalls = m.smalls.map((s) => {
                  s.largeId = l.id;
                  s.largeName = l.name;
                  l.largeCode = l.code;
                  s.middleId = m.id;
                  s.middleName = m.name;
                  m.middleCode = m.code;
                  s.smallId = s.id;
                  s.smallName = s.name;
                  s.smallCode = s.code;
                  this.smalls.push(s);
                  return s;
                });
                return m;
              });
              return l;
            });
          });
      }

      this.functionListener = (e) => {
        if (e.key === "Escape") {
          this.closePopup();
        }
      };
      document.addEventListener("keydown", this.functionListener);

      return new Promise((resolve, reject) => {
        this._save = resolve;
        this._close = reject;
      });
    },
    closePopup() {
      document.removeEventListener("keydown", this.functionListener);
      this.isOpen = false;
      this._close();
    },

    async save() {
      if (this.haveConfirm) {
        var r = await this.$confirmPopup.open();
        if (!r) {
          return;
        }
      }
      this.isOpen = false;
      this._save(this.productSelects);
    },
  },
};
</script>
sear
